import mock from '@/@fake-db/mock'

const hubs = {
    "data": {
        "b9ccebbcc88e0f4069ebb231d7427bc6bd24c8318e1ab44a72a169d3052ae7ec": {
            "Address": "/ip4/127.0.0.1/tcp/10436/p2p/16Uiu2HAmRAH1QL987s2KjJhuo6JovQSfGFXVeUL3PDHWjra5fDAb",
            "LastUpdate": "2023-04-01T22:19:15.552516+05:30"
        },
        "eb12618fcdf413ab2911e573d645f056ffb1f93c4a8917f644bc3f62239b0d1d": {
            "Address": "/ip4/127.0.0.1/tcp/10295/p2p/16Uiu2HAmBFJ3agskX2pXEwmLCwoknQSyLyYRM4Yi4swqniZnCKwJ",
            "LastUpdate": "2023-04-01T22:19:14.42726+05:30"
        }
    }
}

const peers = {
    "data": [
        {
            "id": "f9aaf4f64335735156857de801a9e580eb63b4eaf16653d3112d1a1a3218b9b2",
            "pubkey": "b9ccebbcc88e0f4069ebb231d7427bc6bd24c8318e1ab44a72a169d3052ae7ec",
            "created_at": 1678170854,
            "kind": 1,
            "tags": [
                [
                    "audio",
                    "https://codepipeline-ap-south-1-61245200273.s3.ap-south-1.amazonaws.com/hub_Heene-Geeshan-Attanayake-www.song.lk.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYIHA6YG2WQ4TZVPQ%2F20230307%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230307T063419Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=adb822a31aa7f690f1d5e736f8da999b5a419a63e98dbb1d0501aaa63a1410e7"
                ],
                [
                    "hash",
                    "0xb6d3e26ef4fd09d61b74688ab13708e8d96798266dbe00d74ce93d2c60d735d16b5d4d61d7b66500bf0dfca06282f6af6b36d8c8c4346089773e5846b3a9bc7301",
                    "true"
                ]
            ],
            "content": "gdgdgdgd",
            "sig": "e0445db3341e338532a7725ab72e9b0459b5cafe50864aa6c31e509b7d4e68e2c79f6968e1aa513c6dad0d934b8b695e2b49a7f2d1658b268468ee07fb2fb9a5"
        },
        {
            "id": "878a7edd8cd32345c49d0dde2d3cdc24fd1cd8b533935febfeb1a26bdc32bc9e",
            "pubkey": "b9ccebbcc88e0f4069ebb231d7427bc6bd24c8318e1ab44a72a169d3052ae7ec",
            "created_at": 1678170837,
            "kind": 1,
            "tags": [
                [
                    "hash",
                    "0x47a783364b89fb88d48a2ee06597816ccc9da702239bf552db40215f20bbc3961930ff24568e04ade061b8b5c02e1124e26eab85b6e4b3aa9925ea38a65cfb6401",
                    "true"
                ]
            ],
            "content": "hhdhhdhdhdhh",
            "sig": "bf6fc73361d5637f4fefd76ae1e4beeedc22c8ecdaa815bc777340e478c6310cf1d65b37fda14ea53c0b388dee6e66540256fb92d6cdecdec6e74146c873e326"
        },
        {
            "id": "39502142ffcad8f3610862355370cce96dcd9c97f07d61221bfa553ace513abb",
            "pubkey": "b9ccebbcc88e0f4069ebb231d7427bc6bd24c8318e1ab44a72a169d3052ae7ec",
            "created_at": 1678169834,
            "kind": 1,
            "tags": [
                [
                    "audio",
                    "https://codepipeline-ap-south-1-61245200273.s3.ap-south-1.amazonaws.com/hub_Heene-Geeshan-Attanayake-www.song.lk.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYIHA6YG2WQ4TZVPQ%2F20230307%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230307T061718Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=37c02995f0d75fe526b671e092f3eb0eda23bd16aff2e40bd54738a4d149cc4d"
                ],
                [
                    "hash",
                    "0xef0f7fe7bed4eb6de4f6a244e0faa8a4afc01eccfd6591658eee7558cc4886420308d051eb7479d44d30a40ce651226adc846c0048e51afc358e6f7cb08bc4b301",
                    "true"
                ]
            ],
            "content": "ddgg1111x",
            "sig": "065c9793a98fdcc435a3333f66e4f9b9b84bfcd9ce0e4d11a08554098188296f5db705615dad9fda5bd982086704196bc7ff76e7fc27656e61f9eb490dcb48cb"
        },
        {
            "id": "d0ac9aaf1b5b8ba87b68ab21966dac9764dd5bcc15b6aa4f86d7ecc14f60d175",
            "pubkey": "b9ccebbcc88e0f4069ebb231d7427bc6bd24c8318e1ab44a72a169d3052ae7ec",
            "created_at": 1678169814,
            "kind": 1,
            "tags": [
                [
                    "hash",
                    "0x1036fb7f6f17feecc6f3d5a85b4c7ba7a5130285c31c634c3c72ec1a0f40f22a1689b45298656f89c18c03c52ff8bb792c017a4a79ec8324693081b68803379e00",
                    "true"
                ]
            ],
            "content": "ggdgdgdgdgdgdgdx",
            "sig": "ae9468a3f9a13fb39f7df0ab1cef3e765d14439c8c356124e9d10a91b2d7515b72a8bac4af9d1fb91c4d3262a94eb611ff48a130b59666038e7512256caec69f"
        },
        {
            "id": "0f2816668c8d5eecf395510b167abf662e21a5f0cbe356c0c6ef6b30cb2678f3",
            "pubkey": "b9ccebbcc88e0f4069ebb231d7427bc6bd24c8318e1ab44a72a169d3052ae7ec",
            "created_at": 1678168660,
            "kind": 1,
            "tags": [
                [
                    "audio",
                    "https://codepipeline-ap-south-1-61245200273.s3.ap-south-1.amazonaws.com/hub_Heene-Geeshan-Attanayake-www.song.lk.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYIHA6YG2WQ4TZVPQ%2F20230307%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230307T055746Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=32f411ae2c8108e6421ba19f2404ca7e00354a7a7559c4c7b6cb5cf1d7379142"
                ],
                [
                    "hash",
                    "0xf14de9a9bd258844466161356e7a6e4db83e6cb09e07a95559f2ebd9f5d389bc35ce0e1ac06ff0556735a2d7e1f7ffbdc58820095ccd7d48649c7846177ac1aa00",
                    "true"
                ]
            ],
            "content": "djdjjjw222",
            "sig": "fae248e95bb39c41437507900da6a378684c8a27220501392c24fd19f53301d67c89d57532ae5f1179abadb936de9fdf2522b2e2442931c03521e30fbfff4ad4"
        },
        {
            "id": "0447b2d9d135f70b5431f133fe85a3b74e06a5ef812e3c8af81fe09a51795717",
            "pubkey": "b9ccebbcc88e0f4069ebb231d7427bc6bd24c8318e1ab44a72a169d3052ae7ec",
            "created_at": 1678168640,
            "kind": 1,
            "tags": [
                [
                    "hash",
                    "0xaa13b2210de23d23b2be2587049d44b27a12ced4080f9fa3dcc0589f9893ffac3048cd66e913a856e502843a297115af37103cb3a97fb6b5c59122fa420027ca01",
                    "true"
                ]
            ],
            "content": "uududududud",
            "sig": "3ad2c7cc8977d526814178ff0886fbcfe29dc5a331b80eb965b7ccb48cb61825ab32c6adb61684367ce9230f1551ab53a4ce94ad812f105fed9b0c1bae793e77"
        },
        {
            "id": "a985a02894810751c2c9ad88ea3cd92d81da8865df7f7445f9149518ab67473d",
            "pubkey": "b9ccebbcc88e0f4069ebb231d7427bc6bd24c8318e1ab44a72a169d3052ae7ec",
            "created_at": 1678168541,
            "kind": 1,
            "tags": [
                [
                    "audio",
                    "https://codepipeline-ap-south-1-61245200273.s3.ap-south-1.amazonaws.com/hub_Heene-Geeshan-Attanayake-www.song.lk.mp3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYIHA6YG2WQ4TZVPQ%2F20230307%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230307T055544Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=d9a41af02b0a37dfc1cf9ad47003531980a71c8744c17f9b9e2d4a27db6e935c"
                ],
                [
                    "hash",
                    "0x4a8fedc2691cb32db84275363dc45b21e1c550d9562a8f3cdafa05c5e51e184c6028c173940c8c82d6a0ed302c3e23e41b6342afd63bedd12d1f033bce25550c01",
                    "true"
                ]
            ],
            "content": "22ddd222",
            "sig": "1f6134d15fa4d01418930e246483578b7bdc4bb372f970594c57c0f8ae40edf3dfd83be33e84712d2bd890083facb519112096bc61b6980f6b83c550e48614dd"
        }
    ]
}

mock.onGet('/api/hubs').reply(() => [200, hubs])
mock.onGet('/api/peers').reply(() => [200, peers])